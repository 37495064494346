import React, { useContext, useEffect, useState } from 'react';

import { Button, Checkbox, Pagination } from '@cloudez/cloudez-design-system';

import { useAuth } from 'hooks/auth';

import { Container, Separator, Text, Title } from './styles';

import {
  SecurityCompanyContext,
  SecurityCompanyProvider,
} from './_context/state';

import FinishActiveSectionModal from './FinishActiveSectionModal';
import FinishSectionModal from './FinishSectionModal';
import DevicePlaceholder from './DevicePlaceholder';
import ConfirmationModal from './ConfirmationModal';
import AlertEmailModal from './AlertEmailModal';
import CodeEmailModal from './CodeEmailModal';
import DeleteOtpModal from './DeleteOtpModal';
import CardsSession from './CardsSession';
import AuthModal from './TwoAuthModal';

const Security: React.FC = () => {
  const [isAppActive, setIsAppActive] = useState(false);
  const [isEmailActive, setIsEmailActive] = useState(false);

  const { getOtpStatus, otpStatus, devices, loading } = useContext(
    SecurityCompanyContext,
  );

  const [type, setType] = useState('');

  const { token2FA } = useAuth();

  const [showFinishActiveSectionModal, setShowFinishActiveSectionModal] =
    useState(false);
  const [showFinishSectionModal, setShowFinishSectionModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showAlertEmailModal, setShowAlertEmailModal] = useState(false);
  const [showDeleteOtpModal, setShowDeleteOtpModal] = useState(false);
  const [showCodeEmailModal, setShowCodeEmailModal] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);

  const [deviceId, setDeviceId] = useState('');

  const confirmateIsAuth = type => {
    if (otpStatus?.app || otpStatus?.email) {
      if (!otpStatus?.app && otpStatus?.email) {
        setShowAuthModal(true);
      } else {
        setShowAlertEmailModal(true);
      }
    } else {
      setShowConfirmationModal(true);
    }
    setType(type);
  };

  const deleteOtpStatus = async _type => {
    setShowDeleteOtpModal(true);
    setType(_type);
  };

  useEffect(() => {
    if (otpStatus?.app) {
      setIsAppActive(true);
    }

    if (otpStatus?.email) {
      setIsEmailActive(true);
    }
  }, [otpStatus]);

  const getStatus = async page => {
    await getOtpStatus(
      {
        'X-token2fa': token2FA,
      },
      page,
    );
  };

  useEffect(() => {
    getStatus(1);
  }, [isAppActive, isEmailActive]);

  useEffect(() => {
    if (!showFinishSectionModal) {
      setDeviceId('');
    }
  }, [showFinishSectionModal]);

  return (
    <>
      <Container>
        <Title>Autenticação em Dois Fatores</Title>
        <Text>
          A autenticação em dois fatores aumenta a segurança do seu painel
          requisitando duas diferentes formas de autenticação para fazer o
          login: sua senha de acesso e um código de segurança.
        </Text>
        <Title>
          Aplicativo de Autenticação
          <Checkbox
            checked={isAppActive || otpStatus?.app}
            onChange={
              otpStatus?.app
                ? () => deleteOtpStatus('app')
                : () => confirmateIsAuth('app')
            }
          />
          {isAppActive || otpStatus?.app ? (
            <span className="active">ATIVADO</span>
          ) : null}
        </Title>
        <Text>
          Você também pode usar um aplicativo terceiro para gerar esses códigos
          para você, como o Authy ou o Google Authenticator.
        </Text>
        <Title>
          Receber o Código por Email
          <Checkbox
            checked={isEmailActive || otpStatus?.email}
            onChange={
              otpStatus?.email
                ? () => deleteOtpStatus('email')
                : isAppActive
                ? () => setShowAlertEmailModal(true)
                : () => confirmateIsAuth('email')
            }
          />
          {isEmailActive || otpStatus?.email ? (
            <span className="active">ATIVADO</span>
          ) : null}
        </Title>
        <Text>
          Ao ativar essa opção, quando você realizar o login, irá receber um
          código de 6 dígitos que será enviado para o seu e-mail cadastrado.
          Será necessário inserir esse código para ter acesso ao painel.
        </Text>
      </Container>
      {devices.results?.length > 0 && (
        <>
          <Separator />
          <Container>
            <Title>Dispositivos Confiáveis</Title>
            <Text>
              Esta é uma lista de dispositivos confíaveis que se conectaram à
              sua conta. Encerre qualquer dispositivo que você não reconheça.
            </Text>
            <div className="gridCards">
              {loading ? (
                <>
                  <DevicePlaceholder />
                  <DevicePlaceholder />
                  <DevicePlaceholder />
                  <DevicePlaceholder />
                  <DevicePlaceholder />
                  <DevicePlaceholder />
                </>
              ) : (
                devices.results?.map((device: any) => (
                  <CardsSession
                    device={device}
                    onClick={() => setDeviceId(device.id)}
                    setShowFinishSectionModal={setShowFinishSectionModal}
                    setShowFinishActiveSectionModal={
                      setShowFinishActiveSectionModal
                    }
                  />
                ))
              )}
            </div>
            <div className="footer">
              {devices.count > 6 && (
                <Pagination
                  style={{ marginTop: '0', padding: '0' }}
                  pageSize={6}
                  onChangePage={getStatus}
                  count={devices.count}
                  next={devices.next}
                  previous={devices.previous}
                  current={devices.current}
                />
              )}
              {devices.count > 1 && (
                <Button
                  style={{ marginLeft: 'auto' }}
                  onClick={() => {
                    setDeviceId('');
                    setShowFinishSectionModal(true);
                  }}
                  outline
                  error
                >
                  Encerrar dispositivos
                </Button>
              )}
            </div>
          </Container>
        </>
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          show={showConfirmationModal}
          type={type}
          setShow={setShowConfirmationModal}
          setShowAuth={setShowAuthModal}
          setShowCodeEmail={setShowCodeEmailModal}
        />
      )}
      {showAuthModal && (
        <AuthModal
          show={showAuthModal}
          setShow={setShowAuthModal}
          setIsAppActive={setIsAppActive}
          otpStatus={otpStatus}
        />
      )}

      {showDeleteOtpModal && (
        <DeleteOtpModal
          type={type}
          setIsAppActive={setIsAppActive}
          setIsEmailActive={setIsEmailActive}
          show={showDeleteOtpModal}
          setShow={setShowDeleteOtpModal}
        />
      )}

      {showCodeEmailModal && (
        <CodeEmailModal
          show={showCodeEmailModal}
          setShow={setShowCodeEmailModal}
          setIsEmailActive={setIsEmailActive}
        />
      )}

      {showAlertEmailModal && (
        <AlertEmailModal
          show={showAlertEmailModal}
          setShow={setShowAlertEmailModal}
          setShowCodeEmail={setShowCodeEmailModal}
        />
      )}

      {showFinishActiveSectionModal && (
        <FinishActiveSectionModal
          id={deviceId}
          getStatus={getStatus}
          show={showFinishActiveSectionModal}
          setShow={setShowFinishActiveSectionModal}
        />
      )}

      {showFinishSectionModal && (
        <FinishSectionModal
          token2FA={token2FA}
          getStatus={getStatus}
          id={deviceId}
          show={showFinishSectionModal}
          setShow={setShowFinishSectionModal}
        />
      )}
    </>
  );
};

export default props => (
  <SecurityCompanyProvider>
    <Security {...props} />
  </SecurityCompanyProvider>
);
