import React, { useEffect, useState, useMemo } from 'react';

import { Title, Header } from 'components/Common';
import axios from 'axios';
import history from 'services/history';

import { getCreditcardService } from 'services/common';
import { useAuth } from 'hooks/auth';
import { getUserBillingService } from 'services/user';
import Payment from './Payment';

const InvoiceExternal = ({ match }) => {
  const { user } = useAuth();
  const isAuthenticated = useMemo(() => !!user, [user]);

  const [billing, setBilling] = useState({});
  const [creditCard, setCreditCard] = useState(null);
  const [invoice, setInvoice] = useState(null);

  const [hasInfo, setHasInfo] = useState(false);
  const [hasCreditCard, setHasCreditCard] = useState(false);

  const [loadingHasInfo, setLoadingHasInfo] = useState(false);

  const { hash } = match.params;

  async function getCreditCard() {
    try {
      const apiUrl =
        window.location.hostname === 'demo.cloudez.io'
          ? 'https://dev-api.cloudez.io/'
          : process.env.REACT_APP_API_URL
          ? process.env.REACT_APP_API_URL
          : 'https://api.cloudez.io/';
      const { data } = isAuthenticated
        ? await getCreditcardService()
        : await axios.get(`${apiUrl}v0/creditcard-hash/${hash}/`);

      setCreditCard(isAuthenticated ? data[0] : data);
    } catch (e) {}
  }

  const getBilling = async () => {
    try {
      const { data } = await getUserBillingService(invoice?.user_id);
      setBilling({
        ...data,
        phone_number: data?.phone_number?.substring('+55'.length),
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    async function getInvoice() {
      try {
        const apiUrl =
          window.location.hostname === 'demo.cloudez.io'
            ? 'https://dev-api.cloudez.io/'
            : process.env.REACT_APP_API_URL
            ? process.env.REACT_APP_API_URL
            : 'https://api.cloudez.io/';

        const { data } = await axios.get(`${apiUrl}v3/invoice-hash/${hash}/`);

        setInvoice(data);
        if (data.is_paid) {
          history.push(`/invoices/${data.id}`);
        }
      } catch (e) {
        history.push(`/404`);
      }
    }

    async function getHasInfo() {
      setLoadingHasInfo(true);
      try {
        const apiUrl =
          window.location.hostname === 'demo.cloudez.io'
            ? 'https://dev-api.cloudez.io/'
            : process.env.REACT_APP_API_URL
            ? process.env.REACT_APP_API_URL
            : 'https://api.cloudez.io/';

        const { data } = await axios.get(`${apiUrl}v3/billing-hash/${hash}/`);
        setHasInfo(data.is_complete);
        setHasCreditCard(data.has_creditcard);
        setLoadingHasInfo(false);
      } catch (e) {
        setLoadingHasInfo(false);
      }
    }

    getInvoice();
    getHasInfo();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      getBilling();
      getCreditCard();
    }

    const eventMethod = window.addEventListener
      ? 'addEventListener'
      : 'attachEvent';
    const eventer = window[eventMethod];
    const messageEvent =
      eventMethod === 'attachEvent' ? 'onmessage' : 'message';
    eventer(
      messageEvent,
      e => {
        if (e.data === 'credit_created') {
          getCreditCard();
        }
      },
      false,
    );
  }, [isAuthenticated, invoice]);

  return (
    <>
      <Header>
        <Title>Pagamento</Title>
      </Header>

      {billing && (
        <Payment
          billing={billing}
          creditCard={creditCard}
          hash={match.params.hash}
          setCreditCard={setCreditCard}
          invoice={invoice}
          isAuthenticated={isAuthenticated}
          hasInfo={hasInfo}
          loadingHasInfo={loadingHasInfo}
        />
      )}
    </>
  );
};

export default InvoiceExternal;
