import React, { useCallback, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandsHelping, faPowerOff } from '@fortawesome/free-solid-svg-icons';

import history from 'services/history';
import brasil from 'assets/img/brazil-circle.png';
import giftOpen from 'assets/img/gift-open.png';
import giftClosed from 'assets/img/gift-closed.png';
import apoio from 'assets/img/apoio.png';
import apoioEncolhido from 'assets/img/img-encolhido.png';
import { useLayout } from 'hooks/layout';
import { useAuth } from 'hooks/auth';
import moment from 'moment';
import { useNotifications } from 'hooks/notifications';
import { lighten } from 'polished';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import cybeWeek from 'assets/img/cybe-week.png';
import Logo from './Logo';
import {
  Container,
  MenuList,
  MenuItem,
  SubMenuItem,
  Notification,
  NewBadge,
} from './styles';
import CommercialModal from './CommercialModal';

interface IRoute {
  to: string;
  name: string;
  icon: string;
  id?: string;
  beta?: boolean;
  disabled?: boolean;
  roles?: string[];
}

const Sidebar: React.FC = () => {
  const {
    toggleMenu,
    slug,
    has_performance,
    brand_primary_color,
    is_provider,
  } = useLayout();
  const { user, signOut } = useAuth();
  const { notifications } = useNotifications();
  const [referralIcon, setReferralIcon] = useState(giftClosed);
  const isAuthenticated = !!user;
  const [modal, setModal] = useState(false);
  const theme = useLayout();

  const today = moment();
  const startDateEventAt = moment('2021-12-04');
  const isDateEvent =
    startDateEventAt && startDateEventAt.isAfter(today, 'days');

  const endDateEventAt = moment('2021-11-28');
  const isEndDateEvent =
    endDateEventAt && endDateEventAt.isBefore(today, 'days');

  const migNotifications = notifications?.filter(
    n => n.type === 'migration' && n.status === 0,
  );

  const ticketNotifications = notifications?.filter(
    n => n.type === 'ticket' && n.status === 0,
  );

  const companyIsBlocked = useMemo(
    () =>
      user.is_company_owner && theme.is_blocked && theme.code === user.company,
    [theme, user],
  );

  const isDemo = useMemo(() => {
    return theme.slug === 'demovictor-oiseunegocio-demo';
  }, [theme]);

  const items = useMemo<IRoute[]>((): IRoute[] => {
    return [
      {
        to: '/websites',
        name: 'Websites',
        icon: 'window-maximize',
        id: 'menu-websites',
        roles: ['technical_role', 'customer_success_role'],
        disabled: companyIsBlocked,
      },
      {
        to: '/performance',
        name: 'Performance',
        icon: 'rocket',
        beta: true,
        roles: ['technical_role', 'customer_success_role'],
        disabled: !has_performance || companyIsBlocked || isDemo,
      },
      {
        to: '/emails',
        name: 'Emails',
        icon: 'envelope',
        id: 'menu-emails',
        roles: ['technical_role', 'customer_success_role'],
        disabled: companyIsBlocked,
      },
      {
        to: '/domains',
        name: 'Domínios',
        icon: 'globe',
        id: 'menu-domains',
        roles: ['technical_role', 'customer_success_role'],
        disabled: companyIsBlocked || isDemo,
      },
    ];
  }, [companyIsBlocked, has_performance, isDemo]);

  const subItems = useMemo<IRoute[]>((): IRoute[] => {
    return [
      // submenus
      {
        to: '/migrations',
        name: 'Migrações',
        icon: 'exchange-alt',
        id: 'menu-migrations',
        roles: ['technical_role', 'customer_success_role'],
        disabled: companyIsBlocked || isDemo,
      },
      {
        to: '/databases',
        name: 'Databases',
        icon: 'database',
        id: 'menu-databases',
        roles: ['technical_role', 'customer_success_role'],
        disabled: companyIsBlocked || isDemo,
      },
      {
        to: '/clouds',
        name: 'Clouds',
        icon: 'server',
        id: 'menu-clouds',
        disabled: companyIsBlocked || isDemo,
      },
      {
        to: '/suporte',
        name: 'Suporte',
        icon: 'comment-alt',
        id: 'menu-tickets',
        disabled: is_provider,
      },
    ];
  }, [companyIsBlocked, is_provider, isDemo]);

  const closeSidenav = useCallback(e => {
    if (window.innerWidth <= 768) {
      e.stopPropagation();
      toggleMenu();
    }
    // eslint-disable-next-line
  }, []);

  const changeReferralIcon = useCallback(e => {
    setReferralIcon(e.type === 'mouseenter' ? giftOpen : giftClosed);
  }, []);

  const rolesToSkip = useMemo(
    () => ['company_owner_role', 'customer_role', 'god_role'],
    [],
  );

  return (
    <Container>
      {!is_provider && modal && (
        <CommercialModal setShow={() => setModal(false)} show={modal} />
      )}
      <Logo />

      {isAuthenticated && (
        <MenuList>
          {items.map(
            route =>
              !route.disabled && (
                <MenuItem
                  isActive={history.location.pathname.includes(route.to)}
                  key={route.icon}
                  data-id={route.id}
                  disabled={
                    route.roles &&
                    !route?.roles?.some(role => user?.roles?.includes(role)) &&
                    !user?.roles?.some(role => rolesToSkip.includes(role)) &&
                    !user?.roles?.some(role => role.includes('admin_'))
                  }
                >
                  {route.beta && <NewBadge>Beta</NewBadge>}
                  <Link to={route.to} onClick={closeSidenav}>
                    <FontAwesomeIcon icon={route.icon as IconProp} />
                    <span>{route.name}</span>
                  </Link>
                </MenuItem>
              ),
          )}

          <div
            style={{
              width: '100%',
              padding: '1rem 0',
              backgroundColor: lighten(0.07, brand_primary_color),
            }}
          >
            {subItems.map((route, i) => {
              const hasNot =
                (route.to === '/suporte' && ticketNotifications) ||
                (route.to === '/migrations' && migNotifications);

              return (
                !route.disabled && (
                  <SubMenuItem
                    isActive={history.location.pathname.includes(route.to)}
                    key={route.icon}
                    first={i === 0}
                    last={i === subItems.length - 1}
                    data-id={route.id}
                    disabled={
                      route.roles &&
                      !route?.roles?.some(role =>
                        user?.roles?.includes(role),
                      ) &&
                      !user?.roles?.some(role => rolesToSkip.includes(role)) &&
                      !user?.roles?.some(role => role.includes('admin_'))
                    }
                  >
                    <Link to={route.to} onClick={closeSidenav}>
                      <FontAwesomeIcon icon={route.icon as IconProp} />
                      {hasNot?.length > 0 && (
                        <Notification first={i === 0}>
                          {hasNot.length}
                        </Notification>
                      )}
                      <span>{route.name}</span>
                    </Link>
                  </SubMenuItem>
                )
              );
            })}
          </div>

          {slug === 'configr' && (
            <MenuItem
              referral
              onClick={() => history.push('/referral')}
              onMouseEnter={changeReferralIcon}
              onMouseLeave={changeReferralIcon}
            >
              <img src={referralIcon} alt="referral" />
              Indique e Ganhe
            </MenuItem>
          )}

          {isDateEvent && isEndDateEvent ? (
            <>
              {!is_provider && user?.is_company_owner && (
                <MenuItem apoio onClick={() => setModal(true)}>
                  {/* <img
                    src={theme.sideMenuCollapsed ? cybeWeek : cybeWeek}
                    alt="cybeWeek"
                    style={{
                      width: 62,
                      marginBottom: 10,
                    }}
                  /> */}

                  <span
                    style={{
                      fontWeight: 'bold',
                      color: '#fff',
                      fontSize: 14,
                      lineHeight: '18px',
                    }}
                  >
                    Cyber Week da Cloudez
                  </span>
                  <span
                    style={{
                      fontWeight: 'normal',
                      color: '#fff',
                      fontSize: 14,
                      lineHeight: '18px',
                      marginTop: '8px',
                    }}
                  >
                    NÃO PERCA! Somente até dia 03/12
                  </span>
                </MenuItem>
              )}
            </>
          ) : (
            <>
              {!is_provider && user?.is_company_owner && (
                <MenuItem apoio onClick={() => setModal(true)}>
                  {/* <img
                    src={theme.sideMenuCollapsed ? apoioEncolhido : apoio}
                    alt="apoio"
                    style={{
                      width: 62,
                      marginBottom: 10,
                    }}
                  /> */}
                  <div className="card">
                    <FontAwesomeIcon flip="horizontal" icon={faHandsHelping} />
                    <span style={{ opacity: 1 }}>
                      Apoio <br /> Comercial
                    </span>
                  </div>
                </MenuItem>
              )}
            </>
          )}

          <MenuItem language>
            <p>
              <img alt="brasil" src={brasil} />
              <span>Português</span>
            </p>
          </MenuItem>

          <MenuItem logout>
            <p onClick={() => signOut()}>
              <FontAwesomeIcon icon={faPowerOff} />
              <span>Sair</span>
            </p>
          </MenuItem>
        </MenuList>
      )}
    </Container>
  );
};

export default Sidebar;
